import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'welcome',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'super-admin',
    pathMatch: 'full'
  },
  {
    path: 'merchant/:id/:type',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'super-admin',
    loadChildren: () => import('./super-admin/super-admin.module').then( m => m.SuperAdminPageModule)
  },
  {
    path: 'selectuser',
    loadChildren: () => import('./selectuser/selectuser.module').then( m => m.SelectuserPageModule)
  },
  {
    path: 'dealcreate',
    loadChildren: () => import('./dealcreate/dealcreate.module').then( m => m.DealcreatePageModule)
  },
  {
    path: 'package',
    loadChildren: () => import('./package/package.module').then( m => m.PackagePageModule)
  },
  {
    path: 'createpackage',
    loadChildren: () => import('./createpackage/createpackage.module').then( m => m.CreatepackagePageModule)
  },
  {
    path: 'createbulkuser',
    loadChildren: () => import('./createbulkuser/createbulkuser.module').then( m => m.CreatebulkuserPageModule)
  },
  {
    path: 'distributions',
    loadChildren: () => import('./distributions/distributions.module').then( m => m.DistributionsPageModule)
  },
  {
    path: 'disdetail',
    loadChildren: () => import('./disdetail/disdetail.module').then( m => m.DisdetailPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
