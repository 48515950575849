import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import * as firebase from 'firebase';
import swal from 'sweetalert';
import { BentoService } from '../bento.service';

@Component({
  selector: 'app-distributions',
  templateUrl: './distributions.page.html',
  styleUrls: ['./distributions.page.scss'],
})
export class DistributionsPage implements OnInit {

  selected = 0;
  merchants = [] as any;
  merchants_obj = {} as any;
  users = [] as any;
  users_obj = {} as any;
  voucher = [] as any;
  voucher_obj = {} as any;
  selecteduser = [] as any;
  selectedvoucher = [] as any;
  selectedmerchant = [] as any;
  merkey = '';
  ukey = '';
  vkey = '';
  remarks
  all = [] as any;
  allm = [] as any;
  allv = [] as any;
  aller = false;
  allerm = false;
  allerv = false;

  constructor(private modalCtrl: ModalController, private alertController: AlertController, public navParams: NavParams, public bento: BentoService) { }

  ngOnInit() {
    firebase.database().ref('users').once('value', data => {
      this.users = Object.values(data.val()).filter(a => a['id']);
      this.users_obj = data.val();
      this.all = Object.values(data.val()).map((a) => {
        return a['id'];
      });
    });

    firebase.database().ref('deals').once('value', data => {
      this.voucher = Object.values(data.val()).filter(a => a['id']);
      this.voucher_obj = data.val();
      this.allv = Object.values(data.val()).map((a) => {
        return a['id'];
      });
    });

    firebase.database().ref('merchants').once('value', data => {
      this.merchants = Object.values(data.val()).filter(a => a['id']);
      this.merchants_obj = data.val();
      this.allm = Object.values(data.val()).map((a) => {
        return a['id'];
      });
    });
  } // get data

  checkerall(e) {
    (this.aller == true ? this.selecteduser = this.all : this.selecteduser = this.selecteduser)
  } // select all user

  checkerallm(e) {
    console.log(this.selectedmerchant);
    (this.allerm == true ? this.selectedmerchant = this.allm : this.selectedmerchant = this.selectedmerchant)
  } // select all merchant

  checkerallv(e) {
    (this.allerv == true ? this.selectedvoucher = this.allv : this.selectedvoucher = this.selectedvoucher)
  } // selrct all voucher

  checkinc(x) {
    return this.selecteduser.includes(x);
  } // check user include

  checkincm(x) {
    return this.selectedmerchant.includes(x);
  } // check merchant include

  checkincv(x) {
    return this.selectedvoucher.includes(x);
  } // check voucher include

  pushorremove(x, y) {
    (this.selecteduser.includes(x) == false ? this.selecteduser.push(x) : this.selecteduser.splice(this.selecteduser.findIndex(a => a == x), 1,));
    if (this.aller == true) {
      this.aller = false
    }
  } // add or remove user

  pushorremovem(x, y) {
    (this.selectedmerchant.includes(x) == false ? this.selectedmerchant.push(x) : this.selectedmerchant.splice(this.selectedmerchant.findIndex(a => a == x), 1,));
    if (this.allerm == true) {
      this.allerm = false
    }
  }// add or remove merchant

  pushorremovev(x, y) {
    (this.selectedvoucher.includes(x) == false ? this.selectedvoucher.push(x) : this.selectedvoucher.splice(this.selectedvoucher.findIndex(a => a == x), 1,));
    if (this.allerv == true) {
      this.allerv = false
    }
  } // add or remove voucher

  async distribute() {
    const alert = await this.alertController.create({
      header: 'Confirm Distribution',
      message: 'Are you sure to distribute?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirm',
          handler: () => {

            let key = firebase.database().ref('distributions').push({
              date: firebase.database.ServerValue.TIMESTAMP,
              users: this.selecteduser || [],
              merchants: this.selectedmerchant || [],
              deals: this.selectedvoucher || [],
              remarks: this.remarks || ''
            }).key;

            firebase.database().ref('distributions/' + key).update({ id: key });

            for (let i = 0; i < this.selecteduser.length; i++) {

              // console.log('user x' + i);

              for (let j = 0; j < this.selectedmerchant.length; j++) {
                // console.log('merchant x' + j);
                firebase.database().ref('users/' + this.selecteduser[i] + '/subscribed').update({
                  [this.selectedmerchant[j]]: 0
                })

                firebase.database().ref('merchants/' + this.selectedmerchant[j] + '/subscribers').update({
                  id: this.selecteduser[i],
                  date: firebase.database.ServerValue.TIMESTAMP,
                  type: 'Member',
                })
              }

              for (let k = 0; k < this.selectedvoucher.length; k++) {
                // console.log('voucher x' + k);
                firebase.database().ref('users/' + this.selecteduser[i] + '/deals/' + this.selectedvoucher[k]).update({
                  status: false,
                  voucher_id: this.selectedvoucher[k]
                })

                firebase.database().ref('deals/' + this.selectedvoucher[k] + '/participants/' + this.selecteduser[i]).update({
                  date: firebase.database.ServerValue.TIMESTAMP,
                  email: this.users_obj[this.selecteduser[i]].email,
                  name: this.users_obj[this.selecteduser[i]].name,
                  phone: this.users_obj[this.selecteduser[i]].phone,
                  status: false,
                  type: this.users_obj[this.selecteduser[i]].membership,
                  user_id: this.selecteduser[i],
                  voucher_id: this.selectedvoucher[k]
                })
              }
            }

            this.bento.swal_alert('Success', 'Distributions Success!', 'success', 1000);
            this.dismiss();
          }
        }
      ]
    });

    await alert.present();
  } // distribute vouchers

  checkeruser() {
    if (this.lengthof_obj(this.users) > 0) {
      if (this.ukey != '') {
        return this.users.filter(a => ((a['name'] || '').toLowerCase()).includes(this.ukey.toLowerCase()));
      } else {
        return this.users;
      }

    } else {
      return [];
    }
  } // search user

  checkermerchant() {
    if (this.lengthof_obj(this.merchants) > 0) {
      if (this.merkey != '') {
        return this.merchants.filter(a => ((a['name']).toLowerCase()).includes(this.merkey.toLowerCase()));
      } else {
        return this.merchants
      }
    } else {
      return [];
    }
  } // search merchant

  checkervoucher() {
    if (this.lengthof_obj(this.voucher) > 0) {
      if (this.vkey != '') {
        return this.voucher.filter(a => ((a['name']).toLowerCase()).includes(this.vkey.toLowerCase()));
      } else {
        return this.voucher
      }
    } else {
      return [];
    }
  } // search voucher

  dismiss() {
    this.modalCtrl.dismiss();
  } // dismiss modal

  lengthof_obj(x) {
    if (x) {
      return Object.values(x).length;
    } else {
      return 0
    }
  } // get object length

  lengthof(x) {
    if (x) {
      return x.length;
    }
    else {
      return 0;
    }
  } // get array length
}