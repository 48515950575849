import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CreatebulkuserPageRoutingModule } from './createbulkuser-routing.module';

import { CreatebulkuserPage } from './createbulkuser.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CreatebulkuserPageRoutingModule
  ],
  declarations: [CreatebulkuserPage]
})
export class CreatebulkuserPageModule {}
