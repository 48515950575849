import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
// import { EditpackagePage } from '../editpackage/editpackage.page';
import { CreatepackagePage } from '../createpackage/createpackage.page';
import * as firebase from 'firebase';

@Component({
  selector: 'app-package',
  templateUrl: './package.page.html',
  styleUrls: ['./package.page.scss'],
})
export class PackagePage implements OnInit {

  id;

  types = [] as any;

  member;

  constructor(public modalController: ModalController, public navParam: NavParams) { }

  ngOnInit() {
    this.id = this.navParam.get('id');

    firebase.database().ref('memberships/' + this.id + '/types').once('value', data => {
      this.types = ( data.exists() ?  Object.values(data.val()) : []);
      console.log(this.types);
    });
  } // get data

  // async edit() {
  //   const modal = await this.modalController.create({
  //     component: EditpackagePage,
  //     cssClass: 'my-custom-class',
  //     componentProps: {
  //       id: this.id
  //     }
  //   });
  //   return await modal.present();
  // }

  async create() {
    const modal = await this.modalController.create({
      component: CreatepackagePage,
      cssClass: 'my-custom-class',
      componentProps: {
        id: this.id
      }
    });
    return await modal.present();
  } // open create package

  dismiss(){
    this.modalController.dismiss();
  } // modal dismiss
}
