import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as firebase from 'firebase';

@Component({
  selector: 'app-disdetail',
  templateUrl: './disdetail.page.html',
  styleUrls: ['./disdetail.page.scss'],
})
export class DisdetailPage implements OnInit {

  constructor(public navParam: NavParams, private modalController: ModalController) { }

  id;
  member = [] as any;
  merchant = [] as any;
  voucher = [] as any;
  detail = {} as any;
  selected = 0;

  ngOnInit() {
    this.id = this.navParam.get('id');

    firebase.database().ref('distributions/' + this.id).once('value', data => {
      this.detail = data.val();
      for (let i = 0; i < data.val().users.length; i++) {
        firebase.database().ref('users/' + data.val().users[i]).once('value', user => {
          this.member[i] = {
            id: data.val().users[i],
            name: user.val().name
          }
          console.log(this.member);
        })
      }

      for (let i = 0; i < (data.val().merchants ? data.val().merchants.length : 0); i++) {
        firebase.database().ref('merchants/' + data.val().merchants[i]).once('value', merchant => {
          this.merchant[i] = {
            id: data.val().merchants[i],
            name: merchant.val().name
          }
          console.log(this.merchant);

        })
      }

      for (let i = 0; i < (data.val().deals ? data.val().deals.length : 0); i++) {
        firebase.database().ref('deals/' + data.val().deals[i]).once('value', voucher => {
          this.voucher[i] = {
            id: data.val().deals[i],
            name: voucher.val().name
          }
          console.log(this.voucher);
        })
      }
    })
  } // get data 

  dismiss() {
    this.modalController.dismiss()
  } // dismiss
}
