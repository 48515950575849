import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DealcreatePageRoutingModule } from './dealcreate-routing.module';

import { DealcreatePage } from './dealcreate.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    DealcreatePageRoutingModule
  ],
  declarations: [DealcreatePage]
})
export class DealcreatePageModule {}
