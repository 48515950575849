import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire';
import { FIREBASE_CONFIG } from './app.firebase.config';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { Papa } from 'ngx-papaparse';
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx';
import { PackagePageModule } from './package/package.module'
import { CreatepackagePageModule } from './createpackage/createpackage.module'
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { Device } from '@ionic-native/device/ngx';
import { CreatebulkuserPageModule } from './createbulkuser/createbulkuser.module'
import { DistributionsPageModule } from './distributions/distributions.module';
import { DisdetailPageModule } from './disdetail/disdetail.module';
import { DealcreatePageModule } from './dealcreate/dealcreate.module';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), IonicStorageModule.forRoot(), AppRoutingModule, HttpClientModule, AngularFireModule.initializeApp(FIREBASE_CONFIG), AngularFireAuthModule,
    PackagePageModule,
    CreatepackagePageModule,
    CreatebulkuserPageModule,
    DistributionsPageModule,
    DisdetailPageModule,
    DealcreatePageModule,
  ],
  providers: [
    SafariViewController,
    Papa,
    File,
    FileOpener,
    StatusBar,
    SplashScreen,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    UniqueDeviceID,
    Device
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
