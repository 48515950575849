import { Component, OnInit } from '@angular/core';
import * as EXIF from 'exif-js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModalController, NavParams } from '@ionic/angular';
import * as firebase from 'firebase';

@Component({
  selector: 'app-createpackage',
  templateUrl: './createpackage.page.html',
  styleUrls: ['./createpackage.page.scss'],
})
export class CreatepackagePage implements OnInit {

  merchant = {} as any;

  dkeyword = '';

  selecteddeals = [] as any;

  selectedbdeals = [] as any;

  deals = [] as any;

  infor = {
    birthday: [],
    card_background: '',
    card_font: '',
    card_image: '',
    card_logo: '',
    expiry_duration: 0,
    description: '',
    name: '',
    photo: '',
    point_radio: 0,
    price: 0,
    status: 'Active',
    vouchers: []
  }

  expire_date = new Date().getTime();
  id;

  constructor(public http: HttpClient, public modalCtrl: ModalController, public navParam: NavParams) { }

  ddelete(x) {
    this.selecteddeals = this.selecteddeals.filter(a => a.name !== x);
  } // selected deal delete

  bdelete(x) {
    this.selectedbdeals = this.selectedbdeals.filter(a => a.name !== x);
  } // selected birthday deal delete

  checkerdeals() {
    if (this.lengthof_obj(this.deals) > 0) {
      return (Object.values(this.deals).filter(a => ((a['name']).toLowerCase()).includes(this.dkeyword.toLowerCase())));
    } else {
      return [];
    }
  } // selected birthday deal delete

  dpushdeal(x) {
    this.selecteddeals.push({
      name: this.deals[x].name,
      id: x
    });
  } // add selected deal

  bpushdeal(x) {
    this.selectedbdeals.push({
      name: this.deals[x].name,
      id: x
    });
  } // add selected birthday deal

  createMember() {
    firebase.database().ref('memberships/' + this.id + '/types/' + this.infor.name).update({
      card_background: this.infor.card_background,
      card_font: this.infor.card_font,
      card_image: this.infor.card_image,
      card_logo: this.infor.card_logo,
      description: this.infor.description,
      expiry_duration: this.infor.expiry_duration,
      name: this.infor.name,
      photo: this.infor.photo,
      point_ratio: this.infor.point_radio,
      price: this.infor.price,
      status: "Active",
      birthday: this.selectedbdeals || [],
      vouchers: this.selecteddeals || [],
    })
  } // confirm create membership

  ngOnInit() {
    this.id = this.navParam.get('id');

    firebase.database().ref('merchants/' + this.id).once('value', data => {
      this.merchant = data.val();

      firebase.database().ref('deals').orderByChild('by').equalTo(data.val().name).once('value', data => {
        this.deals = data.val();
      })
    })
  } // get data

  expire() {
    this.expire_date = new Date().getTime() + (this.infor.expiry_duration * 30 * 24 * 60 * 60 * 1000);
  } // auto get expire

  imagectype;
  imagec;
  base64img;
  fileChange(event, name, maxsize) {
    console.log(name)
    if (event.target.files && event.target.files[0] && event.target.files[0].size < (10485768)) {
      eval(name + '="loading images..."');
      this.imagectype = event.target.files[0].type;
      EXIF.getData(event.target.files[0], () => {
        console.log(event.target.files[0])
        console.log(event.target.files[0].exifdata.Orientation);
        var orientation = EXIF.getTag(this, "Orientation");
        var can = document.createElement('canvas');
        var ctx = can.getContext('2d');
        var thisImage = new Image;

        var maxW = maxsize;
        var maxH = maxsize;
        thisImage.onload = (a) => {

          console.log(a)
          var iw = thisImage.width;
          var ih = thisImage.height;
          var scale = Math.min((maxW / iw), (maxH / ih));
          var iwScaled = iw * scale;
          var ihScaled = ih * scale;
          can.width = iwScaled;
          can.height = ihScaled;
          ctx.save();
          var width = can.width; var styleWidth = can.style.width;
          var height = can.height; var styleHeight = can.style.height;
          console.log(event.target.files[0])
          if (event.target.files[0] && event.target.files[0].exifdata.Orientation) {
            console.log(event.target.files[0].exifdata.Orientation)
            if (event.target.files[0].exifdata.Orientation > 4) {
              can.width = height; can.style.width = styleHeight;
              can.height = width; can.style.height = styleWidth;
            }
            switch (event.target.files[0].exifdata.Orientation) {
              case 2: ctx.translate(width, 0); ctx.scale(-1, 1); break;
              case 3: ctx.translate(width, height); ctx.rotate(Math.PI); break;
              case 4: ctx.translate(0, height); ctx.scale(1, -1); break;
              case 5: ctx.rotate(0.5 * Math.PI); ctx.scale(1, -1); break;
              case 6: ctx.rotate(0.5 * Math.PI); ctx.translate(0, -height); break;
              case 7: ctx.rotate(0.5 * Math.PI); ctx.translate(width, -height); ctx.scale(-1, 1); break;
              case 8: ctx.rotate(-0.5 * Math.PI); ctx.translate(-width, 0); break;
            }
          }

          ctx.drawImage(thisImage, 0, 0, iwScaled, ihScaled);
          ctx.restore();

          this.imagec = can.toDataURL();

          let imgggg = this.imagec.replace(';base64,', "thisisathingtoreplace;")
          let imgarr = imgggg.split("thisisathingtoreplace;")
          this.base64img = imgarr[1]
          event.target.value = ''
          this.uploadToImgur(this.base64img, name);
          //console.log(this.imagec)

        }
        thisImage.src = URL.createObjectURL(event.target.files[0]);
        // eval('this.'+el+'.nativeElement.value = null;')
      });
    } else {
      alert("Your Current Image Too Large, " + event.target.files[0].size / (10241024) + "MB! (Please choose file lesser than 8MB)")
    }
  } // read image

  uploadToImgur(base64, name) {
    let headers = new HttpHeaders({
      'Authorization': 'Client-ID 2dc0beb00bb3279'
      // f425e102d31b175576a219bc7d3ba8ad82d85364
    });

    let body = {
      image: base64 // this is the base64img from upper part
    }
    this.http.post(' https://api.imgur.com/3/image ', body, { headers }).subscribe(res => {
      eval(name + '=' + '"' + res['data'].link + '"');
      console.log(name + '=' + '"' + res['data'].link + '"')
    }, awe => {
      console.log(awe)
    })
  } // upload image

  lengthof_obj(x) {
    if (x) {
      return Object.values(x).length;
    } else {
      return 0
    }
  } // get object length

  lengthof(x) {
    return x ? x.length : 0;
  } // get array length

  dismiss() {
    this.modalCtrl.dismiss();
  } // dismiss modal

}
