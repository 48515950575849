import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Papa } from 'ngx-papaparse';
import { HttpClient } from '@angular/common/http';
import * as firebase from 'firebase';
import { ModalController } from '@ionic/angular';
import swal from 'sweetalert';

@Component({
  selector: 'app-createbulkuser',
  templateUrl: './createbulkuser.page.html',
  styleUrls: ['./createbulkuser.page.scss'],
})
export class CreatebulkuserPage implements OnInit {
  @ViewChild('uploadEl', { static: false }) uploadElRef: ElementRef;

  select = 0;

  temp = [] as any;

  merchant = [] as any;

  users = [] as any;

  deals = [] as any;

  keyword = '';

  dkeyword = '';

  ikeyword = '';

  filename;

  package = {} as any;

  memberships = {} as any;

  target = '';
  targetid;

  selectedpackage;

  selectedmerchant = [] as any;
  selectedvoucher = [] as any;
  selecteddeals = [] as any;

  membership = 'Member';

  inviter;

  vkeyword = '';

  constructor(public papa: Papa, public http: HttpClient, public modalCtrl: ModalController) { }

  checkerinviter() {
    if (this.lengthof(this.merchant) > 0) {
      return (Object.values(this.merchant).filter(a => (((a['name'] || []).toLowerCase()).includes(this.ikeyword.toLowerCase()))));
    } else {
      return [];
    }
  } // check merchant for inviter

  checkermerchant() {
    if (this.lengthof(this.merchant) > 0) {
      return (Object.values(this.merchant).filter(a => (((a['name']).toLowerCase()).includes(this.keyword.toLowerCase()) &&
        (this.selectedmerchant.some(c => c['name'] === a['name']) === false))));
    } else {
      return [];
    }
  } // check merchant

  checkerdeals() {
    if (this.lengthof(this.deals) > 0) {
      return (Object.values(this.deals).filter(a => ((a['name']).toLowerCase()).includes(this.dkeyword.toLowerCase())));
    } else {
      return [];
    }
  } // check deal

  checkervoucher(x) {
    if (this.memberships[x]) {
      return (Object.values(this.memberships[x].vouchers).filter(a => ((a['name']).toLowerCase()).includes(this.vkeyword.toLowerCase())));
    } else {
      return [];
    }
  } // check voucher

  delete(x) {
    this.selectedmerchant = this.selectedmerchant.filter(a => a.name !== x);
  } // delete selected merchant

  ddelete(x) {
    this.selecteddeals = this.selecteddeals.filter(a => a.name !== x);
  } // delete selected deals

  vdelete(x) {
    this.selectedvoucher = this.selectedvoucher.filter(a => a.name !== x);
  } // delete selected vouchers

  pushmer(x) {
    this.selectedmerchant.push({
      name: this.merchant[x].name,
      id: x
    });
  } // add merchant

  pushdeal(x) {
    this.selecteddeals.push({
      name: this.deals[x].name,
      id: x
    });
  } // add deal

  pushvoucher(x) {
    this.selectedvoucher.push({
      name: this.memberships[this.targetid].vouchers[x].name,
      id: x
    });
  } // add voucher

  ngOnInit() {
    firebase.database().ref('merchants').once('value', data => {
      this.merchant = data.val();
    });

    firebase.database().ref('users').once('value', data => {
      this.users = data.val();
    });

    firebase.database().ref('deals').orderByChild('exclusive').equalTo(true).once('value', data => {
      this.deals = data.val();
    })

    firebase.database().ref('memberships/').once('value', data => {
      this.memberships = data.val();
    });
  } // get data

  changeListener(res: any): void {
    let csvData = res.target.files[0] || res.srcElement;
    this.uploadElRef.nativeElement.value = ''
    this.papa.parse(csvData, {
      complete: parsedData => {
        console.log(parsedData.data);
        console.log((parsedData.data).splice(0, 1), (parsedData.data).splice(parsedData.data.length - 1, 1))
        this.filename = csvData.name;
        this.temp = parsedData.data;
        console.log(this.temp);
        if ((csvData.name).includes('.csv')) {
          // console.log(this.temp.filter((a, i) => parseInt(a[0]) != i + 1 || ((a[1]).toString()).length! > 0 || (a[2].toString()).length! > 0 ||
          //   parseInt(a[3]) < 0 || parseInt(a[3]) >= 2 && parseInt(a[4]) <= 0 &&
          //   parseInt(a[4]) >= 1 && parseInt(a[5]) < 0 && (a[6].toString()).length < 0 &&
          //   (a[7].toString()).length < 0 && parseInt(a[9]) <= 0 && parseInt(a[9]) >= 1 &&
          //   (parseInt(a[3]) == 2 ? parseInt(a[10]) < 0 : parseInt(a[0]) != i + 1) &&
          //   parseInt(a[3]) == 3 ? parseInt(a[11]) < 0 : parseInt(a[0]) != i + 1));

          swal({
            icon: 'info',
            title: 'Please wait',
            text: 'Processing...',
            buttons: [false],
            closeOnEsc: false,
            closeOnClickOutside: false
          })

          // 0 = index
          // 1 = name 
          // 2 = type (0 = Followers, 1 = Premium )
          // 3 = Gender (0 = Male, 1 = Female)
          // 4 = DOB (YYYY MM DD)
          // 5 = email 
          // 6 = password
          // 7 = phone
          // 8 = Status (0 = Active, 1 = Deleted)

          // && (parseInt(a[3]) == 1 ? parseInt(a[10]) < 0 : parseInt(a[0]) != i + 1) &&
          //   parseInt(a[3]) == 2 ? parseInt(a[11]) < 0 : parseInt(a[0]) != i + 1

          for (let i = 0; i < this.temp.length; i++) {
            if ((Object.values(this.users).filter(a => a['email'] == (this.temp[i][5]))).length == 0) {

              if (this.temp.filter((a) => (a[1].toString()).length <= 0 ||
                parseInt(a[2]) < 0 || parseInt(a[2]) > 2 || parseInt(a[3]) < 0 ||
                parseInt(a[3]) > 1 || parseInt(a[4]) < 0 || (a[5].toString()).length <= 0 ||
                (a[6].toString()).length <= 0 || parseInt(a[8]) < 0 || parseInt(a[8]) > 1).length > 0) {

                console.log('1', this.temp);
                setTimeout(() => {
                  swal.close();
                }, 500);

                for (let i = 0; this.temp.length < i; i++) {
                  alert('something is wrong with line ' + (this.temp)[i][0] + '. Some information is not complete!')
                }

                this.filename = '';
                this.temp = [];
              } else {
                setTimeout(() => {
                  swal.close();
                }, 500);
              }
              console.log('No Duplicate!');
            } else {
              console.log('Duplicate!');
              swal({
                icon: 'error',
                title: 'Error',
                text: 'Duplicate data ' + (this.temp[i][5]) + ', please check your CSV!',
                timer: 2000,
                buttons: [false],
                closeOnEsc: false,
                closeOnClickOutside: false
              })
              this.filename = '';
              this.temp = [];
            }
          }

        } else {
          console.log('2');
          swal({
            icon: 'error',
            title: 'Error',
            text: 'Please upload a CSV file!',
            timer: 2000,
            buttons: [false]
          })

          this.filename = '';
          this.temp = [];
        }
      }
    });
  } // read & check csv validation

  update() {
    swal({
      icon: 'info',
      text: 'Are you sure to create bulk user' + (this.select == 0 ? ' ' : ' for merchant ') + '?',
      title: 'Confirmation',
      buttons: {
        Cancel: true, Confirm: true
      },
      closeOnEsc: false,
      closeOnClickOutside: false
    }).then(ans => {
      if (ans = "Confirm") {
        // 0 = index
        // 1 = name 
        // 2 = type (0 = Followers, 1 = Premium )
        // 3 = Gender (0 = Male, 1 = Female)
        // 4 = DOB (YYYY MM DD)
        // 5 = email 
        // 6 = password
        // 7 = phone
        // 8 = Status (0 = Active, 1 = Deleted)

        swal({
          icon: 'info',
          title: 'Please wait',
          text: 'Processing...',
          buttons: [false],
          closeOnEsc: false,
          closeOnClickOutside: false
        })

        if (this.select == 0) {

          for (let x = 0; x < (this.temp).length; x++) {

            console.log(x + 1, (this.temp).length)

            if (x + 1 == (this.temp).length) {
              swal.close();
              this.modalCtrl.dismiss();
              setTimeout(() => {
                swal({
                  icon: 'success',
                  title: 'Success',
                  text: 'User upload success!',
                  buttons: [false],
                  timer: 2000,
                  closeOnEsc: false,
                  closeOnClickOutside: false
                })
              }, 500);
            }

            // && (parseInt((this.temp)[x][3]) == 1 ? parseInt((this.temp)[x][10]) > 0 : parseInt((this.temp)[x][0]) == x + 1) &&
            //   parseInt((this.temp)[x][3]) == 2 ? parseInt((this.temp)[x][11]) > 0 : parseInt((this.temp)[x][0]) == x + 1

            if (parseInt((this.temp)[x][0]) == x + 1 && ((this.temp)[x][1].toString()).length
              > 0 && parseInt((this.temp)[x][2]) >= 0 && parseInt((this.temp)[x][2]) <= 2 && parseInt((this.temp)[x][3]) >= 0 &&
              parseInt((this.temp)[x][3]) <= 1 && parseInt((this.temp)[x][4]) > 0 && ((this.temp)[x][5].toString()).length > 0 &&
              ((this.temp)[x][6].toString()).length > 0 && parseInt((this.temp)[x][7]) >= 0 && parseInt((this.temp)[x][8]) <= 1) {

              let login_body = {
                email: (this.temp)[x][5],
                password: ((this.temp)[x][6]).toString(),
              }

              let bulk = {
                email: (this.temp)[x][5],
                user_name: (this.temp)[x][1],
                subject: "Welcome To SWITCH-IN™!",
                inviter: this.inviter,
                password: ((this.temp)[x][6]).toString()
              }

              console.log(bulk)

              this.http.post("https://us-central1-switchin-adminv2.cloudfunctions.net/widgets/bulkupload", bulk).subscribe(data => {
                console.log(data);
              });

              this.http.post("https://us-central1-switchin-adminv2.cloudfunctions.net/widgets/createuser", login_body).subscribe(a => {
                firebase.database().ref('users/' + a['user']).update({
                  date: firebase.database.ServerValue.TIMESTAMP,
                  dob: this.makedob((this.temp)[x][4]),
                  email: (this.temp)[x][5],
                  id: a['user'],
                  gender: ((this.temp)[x][3] == 0 ? 'Male' : 'Female'),
                  membership: ((this.temp)[x][2] == 0 ? 'Followers' : 'Premium'),
                  name: (this.temp)[x][1],
                  online: false,
                  online_last: firebase.database.ServerValue.TIMESTAMP,
                  password: ((this.temp)[x][6]).toString(),
                  phone: ((this.temp)[x][7]).toString(),
                  photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQAZyFdywMPXJlTpPImDs4x5RkRvjCqAk6tsA&usqp=CAU',
                  status_login: "email",
                  status_user: true,
                  fcm: "",
                }).then(() => {


                  for (let i = 0; i < this.selectedmerchant.length; i++) {
                    firebase.database().ref('users/' + a['user'] + '/subscribed').update({
                      [this.selectedmerchant[i].id]: 0
                    })
                  }

                  for (let i = 0; i < this.selecteddeals.length; i++) {
                    firebase.database().ref('users/' + a['user'] + '/deals/' + this.selecteddeals[i].id).update({
                      status: false,
                      voucher_id: this.selecteddeals[i].id
                    })

                    firebase.database().ref('deals/' + this.selecteddeals[i].id + '/participants/' + a['user']).update({
                      date: firebase.database.ServerValue.TIMESTAMP,
                      email: (this.temp)[x][5],
                      name: (this.temp)[x][1],
                      phone: ((this.temp)[x][7]).toString(),
                      status: false,
                      type: this.membership,
                      user_id: a['user'],
                      voucher_id: this.selecteddeals[i].id
                    })
                  }

                });
                console.log(a);
              }, e => {
                alert('something is wrong with user number ' + (this.temp)[x][0] + '. Email already exist OR password format invalid!')
                console.log(e);
              })

            } else {
              alert('something is wrong with user number ' + (this.temp)[x][0] + '. Some information is not complete!')
            }
            // console.log(holder);
          }

        }

      }
    })

  } // bulk upload confirmation

  findmembership(x) {
    return (this.memberships[x] ? Object.values(this.memberships[x].types) : []);
  } // search membership type

  makedob(x) {
    if (x) {
      //ddmmyyyy
      let day = x.substring(0, 2);
      let month = x.substring(2, 4);
      let year = x.substring(4, 8)
      return day + '/' + month + '/' + year;
    } else {
      return "";
    }
    // if(x){
    //   let day = x.substring(0,2);
    //   let month = x.substring(2,4);
    //   let year = x.substring(4,8)
    //   return new Date(parseInt(year),parseInt(month)-1,parseInt(day),0,0,0).getTime();
    // }else{
    //   return new Date().getTime();
    // }


  } // dob format

  dismiss() {
    this.modalCtrl.dismiss();
  } // dismiss modal

  lengthof(x) {
    if (x) {
      return Object.values(x).length;
    } else {
      return 0;
    }
  } // get object length

  lengthof_arr(x) {
    if (x) {
      return x.length;
    } else {
      return 0;
    }
  } // get array length

}
