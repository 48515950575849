import { Component, OnInit } from '@angular/core';
import { ModalController, Platform, AlertController } from '@ionic/angular';
import * as firebase from 'firebase';
import { BentoService } from '../bento.service';

@Component({
  selector: 'app-dealcreate',
  templateUrl: './dealcreate.page.html',
  styleUrls: ['./dealcreate.page.scss'],
})
export class DealcreatePage implements OnInit {

  constructor(public alertController: AlertController, public viewCtrl: ModalController, public bento: BentoService, public platform: Platform) { }

  selected = 0;

  makerec(x, y) {
    eval(y + '.push(x);')
  } // push recommend

  doReorder(ev: any, y) {
    eval(y + "=ev.detail.complete(" + y + ");")
  } // push recommend

  remrec(x, y) {
    eval(y + '.splice(x,1);')
    console.log(eval(y))
  } // remove recommend

  async save() {
    const alert = await this.alertController.create({
      header: 'Save Changes',
      message: 'Are you sure?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirm',
          handler: () => {
            firebase.database().ref('deal_recommend').set(this.deal_recommend)
            firebase.database().ref('event_recommend').set(this.event_recommend)
            this.bento.swal_alert("Save Changes", "All Information Saved", "success", 2000);
          }
        }
      ]
    });

    await alert.present();
  } // save recommend

  filterif(x, y) {
    return x.filter(a => (y ? y.includes(a[0]) == false : true));
  } // filter deal  

  ngOnInit() {
    firebase.database().ref('event_recommend').once('value', data => {
      this.event_recommend = (data.val() || [])
      console.log(this.event_recommend)
    })

    firebase.database().ref('deal_recommend').once('value', data => {
      this.deal_recommend = (data.val() || [])
      console.log(this.deal_recommend)
    })

    firebase.database().ref('deals').once('value', data => {
      this.deals = Object.entries(data.val())
      this.deals_obj = data.val();
      console.log(this.deals_obj)
    })

    firebase.database().ref('events').once('value', data => {
      this.events = Object.entries(data.val())
      this.events_obj = data.val();
    })

  } // get data

  event_recommend = [];
  deal_recommend = [];
  deals_obj = {};
  deals = [];
  events_obj = {};
  events = [];

  uploader(s, where, maxsize) {
    console.log(where);
    this.bento.imgur(s, maxsize).then(a => {
      console.log(a)
      eval(where + '="' + a + '"')
    }).catch(e => {
      console.log(e)
    })
  } // upload image

  widther(x) {
    return this.platform.width() >= x;
  } // get platform width

  ok(s) {
    console.log(s.target.value);
  } // test

  deal = {
    by: "",
    capacity: 0,
    category: [],
    date: firebase.database.ServerValue.TIMESTAMP,
    date_end: this.bento.todatetimeformat(new Date().getTime() + 30 * 24 * 3600000),
    date_start: this.bento.todatetimeformat(new Date().getTime()),
    date_begin: this.bento.todatetimeformat(new Date().getTime() + 30 * 24 * 3600000),
    date_expiry: this.bento.todatetimeformat(new Date().getTime() + 60 * 24 * 3600000),
    description_long: "",
    id: "",
    keywords: [],
    latitude: 0,
    longitude: 0,
    merchant_id: "",
    name: "",
    participants: {},
    photo: [
      {
        media: "photo",
        url: "",
      }
    ],
    price_normal: 0,
    price_ori: 0,
    price_vip: 0,
    reminder: "",
    ribbon_normal: "",
    ribbon_vip: "",
    status: "Active",
    terms: "",
    thumbnail_banner: "",
    thumbnail_square: "",

  }

  dismiss() {
    this.viewCtrl.dismiss();
  } // modal dismiss

  completed() {
    return false;
  } // test

}
