import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CreatepackagePageRoutingModule } from './createpackage-routing.module';

import { CreatepackagePage } from './createpackage.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CreatepackagePageRoutingModule
  ],
  declarations: [CreatepackagePage]
})
export class CreatepackagePageModule {}
